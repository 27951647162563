import useUser from "@/hooks/useUser";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {Skeleton} from "@/components/ui/skeleton";
import useDarkMode from "@/hooks/useDarkMode";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {Ellipsis} from "lucide-react";
import {cn} from "@/lib/utils";
import {useUserSubscriptionStore} from "@/modules/subscription/UserSubscriptionPanel";

const UserMenu = () => {
    const {user, isLoading, logout, subscriptionEndDays} = useUser();

    const {isDarkMode, setIsDarkMode} = useDarkMode();

    const {t} = useTranslation();
    const {setIsPricingPanelOpen} = useUserSubscriptionStore((state) => state as any);

    const setAppearanceMode = (mode: string) => {
        if (mode === "dark") {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    }

    if (!isLoading && !user) return <></>;

    const UserMenuButtonContent = () => {
        if (isLoading) {
            return <>
                <Skeleton className="size-12 rounded-full"/>
                <Skeleton className="w-[100px] h-[20px] flex-grow"/>
            </>
        }

        const displayName = user?.first_name ?? user?.last_name ?? user?.email?.split("@")[0].replace(".", " ");

        return <>
            <ObjectAvatar
                className="size-12"
                object={user}
            />

            <div className="flex-grow flex flex-col items-start">
                <p className="font-display text-left">
                    {displayName}
                </p>
                { subscriptionEndDays!==null && <>
                    <p className={cn('inline-block bg-primary-300 text-primary-foreground rounded-md px-1 text-xs', {
                        'bg-destructive text-destructive-foreground' : subscriptionEndDays<=0,
                    })}>
                        {subscriptionEndDays>0 ? <>
                            <span className="font-bold">
                                {subscriptionEndDays}
                            </span>&nbsp;
                            {t('user.subscription_days_left')}
                        </> : <>
                            {t('user.subscription_ended')}
                        </>}
                    </p>
                </> }
            </div>

            <Ellipsis className="space-4" />
        </>
    }

    const UserMenuButton = () => (
        <div
            className="flex space-x-4 items-center bg-background pl-2 py-2 pr-4 rounded-full"
        >
            <UserMenuButtonContent/>
        </div>
    )

    if (isLoading) {
        return <UserMenuButton/>
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                className="!outline-none"
            >
                <UserMenuButton/>
            </DropdownMenuTrigger>

            <DropdownMenuContent>
                {user && (<>
                    <DropdownMenuItem asChild>
                        <Link
                            to="/user"
                        >
                            {t('common.user.settings')}
                        </Link>
                    </DropdownMenuItem>

                    {subscriptionEndDays!==null && <DropdownMenuItem onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        setIsPricingPanelOpen(true);
                    }} className="font-bold">
                            {t('user.upgrade_subscription')}
                    </DropdownMenuItem>}
                </>)}
                <DropdownMenuSeparator/>
                <DropdownMenuLabel>
                    {t('common.appearance.title')}
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                    value={isDarkMode ? "dark" : "light"}
                    onValueChange={setAppearanceMode}
                >
                    <DropdownMenuRadioItem
                        value="light"
                    >
                        {t('common.appearance.light')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem
                        value="dark"
                    >
                        {t('common.appearance.dark')}
                    </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => logout()}>
                    {t('common.user.logout')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default UserMenu