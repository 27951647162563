import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card";
import {Button} from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "@/components/ui/dialog";
import useUser from "@/hooks/useUser";
import {useMemo, useState} from "react";
import slugify from "slugify";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {useDeleteUserMutation} from "@/api/user";

const UserDangerZone = () => {
    const {user} = useUser();

    const [deleteUser, {
        isLoading: isDeleting
    }] = useDeleteUserMutation();

    const wantedConfirmToken = useMemo(()=>{
        return 'delete-'+(slugify(user?.display_name??user?.email?.split('@')[0] ?? user?.uuid ?? '').toLowerCase());
    }, [user]);

    const [confirmToken, setConfirmToken] = useState<string>('');

    return (
        <>
            <div className="space-y-4">
                <Card>
                    <CardHeader>
                        <CardTitle className="text-xl rounded-sm">
                            Delete your account
                        </CardTitle>
                        <CardDescription>
                            You can delete your account and all the data associated with it.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="flex items-end justify-end">
                            <Dialog>
                                <DialogTrigger asChild>
                                    <Button variant="destructive">
                                        Delete my account
                                    </Button>
                                </DialogTrigger>
                                <DialogContent>
                                    <DialogHeader>
                                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                                        <DialogDescription>
                                            This action cannot be undone. This will permanently delete your account
                                            and remove your data from our servers.
                                        </DialogDescription>
                                    </DialogHeader>

                                    <div className="flex flex-col gap-4">
                                        <Label
                                            htmlFor="confirm_token"
                                            className="font-bold"
                                        >
                                            Type <code>{wantedConfirmToken}</code> below to confirm
                                        </Label>
                                        <Input
                                            disabled={isDeleting}
                                            type="text"
                                            placeholder={wantedConfirmToken}
                                            className="w-full outline-none bg-transparent placeholder:text-gray-400 font-mono"
                                            name="confirm_token"
                                            value={confirmToken}
                                            onChange={(e) => setConfirmToken(e.target.value)}
                                        />
                                        <Button
                                            disabled={confirmToken !== wantedConfirmToken||isDeleting}
                                            variant="destructive"
                                            onClick={() => {
                                                deleteUser().then(() => {
                                                    window.location.href = '/';
                                                });
                                            }}
                                        >
                                            Delete my account permanently
                                        </Button>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </CardContent>
                </Card>
            </div>
            </>
)
            }

export default UserDangerZone;