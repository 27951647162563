import {Helmet} from "react-helmet";
import React, {useEffect, useRef} from "react";
import {
    useGetListPipelineQuery,
    useGetListQuery,
    useLaunchAutoMoveContactsMutation,
    usePatchListPipelineMutation,
    usePatchListPipelineStageMutation,
    useSetListPipelineStagesOrderMutation
} from "@/api/list";
import {useParams} from "react-router";
import StageContainer from "./pipelines/components/StageContainer";
import {ReactSortable} from "react-sortablejs";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {SlidersHorizontal} from "lucide-react";
import {Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet";
import {Checkbox} from "@/components/ui/checkbox";
import {clsx} from "clsx";
import {Slider} from "@/components/ui/slider";
import {Button} from "@/components/ui/button";

const Pipelines = () => {
    const {t} = useTranslation();

    const {listUuid: listUuidParam} = useParams();

    const {data} = useGetListPipelineQuery({
        uuid: listUuidParam ?? ''
    });

    const [patchPipeline] = usePatchListPipelineMutation();
    const [patchPipelineStage] = usePatchListPipelineStageMutation();

    const [updateListPipelineStagesOrder] = useSetListPipelineStagesOrderMutation();

    const [launchAutoMoveContacts] = useLaunchAutoMoveContactsMutation();

    const sortableStages = (data?.stages ?? []).map((stage) => ({
        ...stage,
        id: stage.uuid
    }));

    const stagesOrder = (data?.stages ?? []).map((stage) => stage.uuid);

    const {data: list} = useGetListQuery({
        uuid: listUuidParam ?? ''
    });
    const setStagesOrder = (newStages: typeof sortableStages) => {
        const newStagesOrder = newStages.map((stage) => stage.uuid);
        if (newStagesOrder.join(',') !== stagesOrder.join(',')) {
            updateListPipelineStagesOrder({
                uuid: listUuidParam ?? '',
                stageUuids: newStagesOrder
            });
        }
    }

    const sliderChangedTimeout = useRef<any>(null);
    
    const handleSliderValueChange = (uuid:string, value: number[]) => {
        if(sliderChangedTimeout.current){
            clearTimeout(sliderChangedTimeout.current);
        }
        sliderChangedTimeout.current = setTimeout(()=>{
            patchPipelineStage({
                uuid,
                listUuid: listUuidParam,
                scoreTrigger: value[0]
            })
        }, 500);
    }

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('pipelines.page_title'));
    }, [setCrumbs, setTitle, t]);

    return (
        <>
            <Helmet>
                <title>{t('pipelines.page_title')}</title>
            </Helmet>

            <div
                className="p-4">
                <div
                    className="flex justify-end gap-4"
                >
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button variant="outline" className="flex items-center gap-2">
                                Settings
                                <SlidersHorizontal className="size-4"/>
                            </Button>
                        </SheetTrigger>
                        <SheetContent
                            className="!w-[600px] !max-w-full"
                        >
                            <SheetHeader>
                                <SheetTitle>
                                    {t('pipelines.settings.title')}
                                </SheetTitle>
                                <SheetDescription>
                                    {t('pipelines.settings.description')}
                                </SheetDescription>
                            </SheetHeader>

                            <div className="mt-8">
                                <div className="items-top flex space-x-2">
                                    <Checkbox id="auto_move_enabled"
                                              checked={!!data?.autoMoveEnabled}
                                              onCheckedChange={(value) => {
                                                  patchPipeline({
                                                      uuid: listUuidParam ?? '',
                                                      autoMoveEnabled: !!value
                                                  });
                                              }}
                                    />
                                    <div className="grid gap-1.5 leading-none mt-0.5">
                                        <label
                                            htmlFor="auto_move_enabled"
                                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                        >
                                            {t('pipelines.settings.auto_move_enabled')}
                                        </label>
                                        <p className="text-sm text-muted-foreground">
                                            {t('pipelines.settings.auto_move_enabled_description')}
                                        </p>
                                    </div>
                                </div>

                                {data?.autoMoveEnabled && <div
                                    className={clsx([
                                        "space-y-4 mt-4"
                                    ])}
                                >
                                    {data.stages.map((stage, index) => (
                                        <div key={stage.uuid}>
                                            <p className="mb-2 text-sm">{stage.name}</p>

                                            <div className="flex items-center gap-2">
                                                <Slider
                                                    defaultValue={[stage.scoreTrigger ?? 0]}
                                                    disabled={!index}
                                                    max={100}
                                                    step={1}
                                                    onValueChange={(value)=>handleSliderValueChange(stage.uuid, value)}
                                                />
                                                <p className="w-6 text-right text-xs">
                                                    {stage.scoreTrigger ?? 0}
                                                </p>
                                            </div>
                                        </div>
                                    ))}

                                    <Button
                                        onClick={()=>{
                                            launchAutoMoveContacts().then();
                                        }}
                                        className="mt-4 w-full"
                                    >
                                        Move contacts
                                    </Button>
                                </div>}
                            </div>
                        </SheetContent>
                    </Sheet>
                </div>


                {list &&
                    <div className="mt-4">
                        <ReactSortable list={sortableStages} setList={setStagesOrder} className="flex">
                            {sortableStages.map((stage) => (
                                <StageContainer list={list} key={stage.uuid} stage={stage}/>
                            ))}
                        </ReactSortable>
                    </div>
                }

            </div>
        </>
    )
}

export default Pipelines