import React from "react";
import {Contact} from "@/api/contact.types";
import ScoreChip from "@/modules/contacts/components/ScoreChip";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import ContactActions from "@/modules/contacts/components/ContactActions";

type ContactHeaderProps = {
    contact: Contact
}

const ContactHeader = ({contact}: ContactHeaderProps) => {
    return (
        <div className="p-8 w-full">
            <div className="flex gap-8">
                <div className="relative">
                    <ObjectAvatar
                        object={contact}
                        className="size-24"
                    />

                    <div
                        className="absolute top-0 right-0"
                    >
                        <ScoreChip contact={contact} variant="rounded"/>
                    </div>
                </div>

                <div className="flex-grow h-full flex flex-col">
                    <h1 className="text-2xl font-bold font-display">
                        {contact.display_name}
                    </h1>
                    <p
                        className="text-sm opacity-50"
                    >
                        {contact.company_position ?? contact.company_name}
                        {contact.company_name && contact.company_position && ' @ '}
                        {contact.company_name}
                    </p>

                    <div className="flex-grow"/>
                </div>

                <div>
                    <ContactActions contact={contact} variant="list-menu"/>
                </div>
            </div>
        </div>
    )
}

export default ContactHeader;