import {Button} from "@/components/ui/button";
import {useTranslation} from "react-i18next";
import {LinkedinIcon} from "lucide-react";

const apiHost = process.env.REACT_APP_API_HOST;
export const redirectUrl = `${apiHost}/user/linkedin/redirect`;

const LinkedInSignIn = () => {
    const {t} = useTranslation();

    // // Redirect to redirectUrl on load for now
    // useEffect(() => {
    //     window.location.href=redirectUrl;
    // }, []);

    return (
        <Button
            asChild
            variant="outline"
        >
            <a href={redirectUrl}>
                <LinkedinIcon className="h-4 w-auto mr-2"/>
                {t('sign_in.linkedin_button')}
            </a>
        </Button>
    )
}

export default LinkedInSignIn