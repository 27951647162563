import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {clsx} from "clsx";
import UserServices from "@/modules/user/panels/UserServices";
import UserAccount from "@/modules/user/panels/UserAccount";
import UserDangerZone from "@/modules/user/panels/UserDangerZone";

const User = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('user.page_title'))
    }, [setCrumbs, setTitle, t]);

    return (
        <>
            <Helmet>
                <title>
                    {t('user.page_title')}
                </title>
            </Helmet>

            <Tabs defaultValue="account" className={clsx([
                'p-4'
            ])}>
                <TabsList>
                    <TabsTrigger value="account">
                        {t('user.account')}
                    </TabsTrigger>
                    <TabsTrigger value="services" disabled={true}>
                        {t('user.services')}
                    </TabsTrigger>
                    <TabsTrigger value="danger_zone" className="text-destructive">
                        {t('user.danger_zone')}
                    </TabsTrigger>
                </TabsList>
                <TabsContent value="account">
                            <UserAccount/>
                </TabsContent>
                <TabsContent value="services">
                            <UserServices/>
                </TabsContent>
                <TabsContent value="danger_zone">
                            <UserDangerZone/>
                </TabsContent>
            </Tabs>

        </>

    )
}

export default User