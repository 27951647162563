import {useGetUserStripePortalMutation, useGetUserSubscriptionsQuery} from "@/api/user";
import dayjs from "dayjs";
import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "@/components/ui/card";
import {Button} from "@/components/ui/button";
import {useUserSubscriptionStore} from "@/modules/subscription/UserSubscriptionPanel";

const UserAccount = () => {
    const {data:subscriptions, isLoading} = useGetUserSubscriptionsQuery();

    const activeSubscriptions = subscriptions?.filter((subscription) =>{
        return subscription.status === 'active' || subscription.status === 'trialing';
    });

    const [getPortalUrl] = useGetUserStripePortalMutation();

    const {setIsPricingPanelOpen} = useUserSubscriptionStore((state) => state as any);

    if(isLoading) return null;

    return (
        <>
            <div className="space-y-4">
                {activeSubscriptions?.map((subscription) => (
                    <Card key={subscription.createdAt}>
                        <CardHeader>
                            <CardTitle className="text-xl rounded-sm">
                                <span className="capitalize">{subscription.status}</span> subscription
                            </CardTitle>
                            <CardDescription>Since {dayjs(subscription.createdAt).format('LL')}</CardDescription>
                        </CardHeader>
                        <CardContent>
                            {subscription.status==='trialing'&&<p className="mb-4 bg-primary-300 text-primary-foreground rounded-sm py-2 px-4 text-sm font-bold">
                                Your trial will end on {dayjs(subscription.trialEndsAt).format('LL')}.
                            </p>}

                            {(subscription.status==='trialing'||subscription.status==='active')&&
                            <div className="flex justify-end">
                                <Button onClick={(e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    getPortalUrl().then((response)=>{
                                        window.open(response?.data?.redirectUrl, '_self');
                                    });
                                }}>
                                    Manage subscription
                                </Button>
                            </div>
                            }
                        </CardContent>
                    </Card>
                ))}

                {!activeSubscriptions?.length&&<Card>
                    <CardHeader>
                        <CardTitle className="text-xl">
                            No active subscription
                        </CardTitle>
                        <CardDescription>
                            You don't have any active subscription.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Button onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();

                            setIsPricingPanelOpen(true);
                        }}>
                            Upgrade your subscription
                        </Button>
                    </CardContent>
                </Card>}
            </div>
        </>
    )
}

export default UserAccount;