import {useGetUserQuery, useGetUserRightsQuery, useGetUserSettingsQuery, useLogoutUserMutation} from "@/api/user";
import dayjs from "dayjs";

const useUser = () => {
    const {data: user, isLoading, error, refetch} = useGetUserQuery();

    const {
        data: userSettings
    } = useGetUserSettingsQuery()

    const {data:userRights} = useGetUserRightsQuery();
    const subscriptionEndDays = userRights?.limits?.limit_date ? dayjs(userRights.limits.limit_date).diff(dayjs(), 'days') : null;

    const [logout] = useLogoutUserMutation();

    return {
        user,
        userSettings,
        subscriptionEndDays,
        isLoading,
        error,
        refetch,
        logout
    }
}

export default useUser;